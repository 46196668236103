<template>
  <div style="width: 100%">
    <v-row dense no-gutters>
      <v-col cols="12">
        <Table
          :key="tableKey"
          :loading="loading"
        ></Table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Table from './BrowseDataDatatable.vue'
import { mapActions } from 'vuex'

export default {
  components: {
    Table
  },
  data() {
    return {
      tableKey: 0,
      loading: false
    }
  },
  created() {
    if (this.$route.params && this.$route.params.message) {
      this.addSnackbar({
        message: this.$route.params.message,
        timeout: 5000,
        color: 'success'
      })
    }
  },
  methods:{
    ...mapActions('app', ['addSnackbar'])

  }
}
</script>
